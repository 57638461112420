const ArgumentType = require('../../extension-support/argument-type')
const BlockType = require('../../extension-support/block-type')
// const Clone = require('../../util/clone');
// const Cast = require('../../util/cast');
// const formatMessage = require('format-message');
// const MathUtil = require('../../util/math-util');
// const Timer = require('../../util/timer');

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

class Scratch3StarkBlocks {
  constructor(runtime) {
    this.runtime = runtime
  }

  /**
   * @returns {object} metadata for this extension and its blocks.
   */

  getInfo() {
    return {
      id: 'starkblocks',
      name: 'Stark Blocks',
      blocks: [
        /* {
          opcode: 'myReporter',
          blockType: BlockType.REPORTER,
          text: 'letter [LETTER_NUM] of [TEXT]',
          arguments: {
            LETTER_NUM: {
              type: ArgumentType.STRING,
              defaultValue: '1',
            },
            TEXT: {
              type: ArgumentType.STRING,
              defaultValue: 'text',
            },
          },
        }, */

        {
          opcode: 'findDay',
          blockType: BlockType.REPORTER,
          text: 'What day of the week is [DATE]/[MONTH]/[YEAR]',
          arguments: {
            MONTH: {
              type: ArgumentType.NUMBER,
            },
            DATE: {
              type: ArgumentType.NUMBER,
            },
            YEAR: {
              type: ArgumentType.NUMBER,
            },
          },
        },
      ],
    }
  }

  myReporter(args) {
    return args.TEXT.charAt(args.LETTER_NUM)
  }

  findDay(args) {
    const date = args.DATE,
      month = args.MONTH,
      year = args.YEAR

    if (date && month && year) {
      const d = new Date(year, month - 1, date)
      const day = d.getDay()
      if (day > -1) return weekdays[day]
      // else continue
    }

    console.log(args)
    console.warn('invalid date')

    return 'invalid date'
  }
}

module.exports = Scratch3StarkBlocks
